.popup__input-container {
  max-width: calc(100% - 72px);
  border: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 34px auto 37px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media all and (max-width: 500px) {
  .popup__input-container {
    max-width: calc(100% - 44px);
    margin: 25px auto;
  }
}
