.popup__container {
  position: relative;
  min-width: 430px;
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 500px) {
  .popup__container {
    min-width: calc(100% - 38px);
  }
}
