.element__like-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: url(../../../images/like.svg) center no-repeat;
  background-size: cover;
  border: none;
  min-width: 22px;
  min-height: 19px;
  padding: 0;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.element__like-button:hover {
  cursor: pointer;
  opacity: 0.5;
}
