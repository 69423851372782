.profile__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  -o-object-fit: cover;
     object-fit: cover;
}

