.profile__add-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #000;
  background: url(../../../images/profile-add-button.svg) center no-repeat;
  background-size: 22px;
  min-width: 150px;
  min-height: 50px;
  border: 2px solid #fff;
  border-radius: 2px;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media all and (max-width: 720px) {
  .profile__add-button {
    min-width: 282px;
    min-height: 50px;
    background-size: 16px;
  }
}
