.popup__caption {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
