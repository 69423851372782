.user-form__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
  margin: 0 0 50px;
  text-align: center;
}

@media all and (max-width: 450px) {
  .user-form__title {
    margin-bottom: 38px;
    font-size: 20px;
    line-height: 24px;
  }
}
