.profile {
  max-width: 880px;
  margin: 0 auto 51px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
}

@media all and (max-width: 950px) {
  .profile {
    max-width: calc(100% - 38px);
  }
}

@media all and (max-width: 720px) {
  .profile {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 36px;
  }
}
