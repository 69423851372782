.header__logo {
  max-width: 142px;
  background-size: cover;
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media all and (max-width: 920px) {
  .header__logo {
    margin-left: 30px;
    margin-bottom: 31.6px;
    margin-top: 28px;
  }
}

@media all and (max-width: 600px) {
  .header__logo {
    max-width: 103.74px;
  }
}
