.header__close-menu {
  display: none;
  margin-top: 31.5px;
  margin-right: 30px;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  background: url(../../../images/close-button.svg) center no-repeat;
  background-size: cover;
  min-width: 20px;
  max-height: 20px;
}

@media all and (max-width: 600px) {
  .header__close-menu {
    display: flex;
  }
}
