.profile__subtitle {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media all and (max-width: 720px) {
  .profile__subtitle {
    max-width: 100%;
    font-size: 14px;
    line-height: 17px;
  }
}
