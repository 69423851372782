.footer {
  max-width: 880px;
  margin: 67px auto 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #545454;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

@media all and (max-width: 920px) {
  .footer {
    margin: 48px 19px 36px;
  }
}

@media all and (max-width: 720px) {
  .footer {
    font-size: 14px;
    line-height: 17px;
  }
}
