.loading__container {
  width: 160px;
  height: 160px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: transparent;
  border: 4px solid #3c3c3c;
  border-radius: 50%;
  font-size: 23px;
  line-height: 150px;
  text-align: center;
  color: #6cceee;
  letter-spacing: 2px;
  text-shadow: 0 0 17px #6cceee;
  box-shadow: 0 0 20px rgba(0, 0, 0, .5);
}

.loading__container::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: -3px;
  top: -3px;
  border: 4px solid transparent;
  border-top: 4px solid #6cceee;
  border-right: 4px solid #6cceee;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
