.profile__edit-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: url(../../../images/profile-edit-button.svg) center no-repeat;
  background-size: 10px;
  background-color: #000;
  min-width: 24px;
  max-height: 24px;
  border: 1px solid #fff;
  margin-top: 16px;
  padding: 0;
}

@media all and (max-width: 720px) {
  .profile__edit-button {
    margin-top: 8px;
    margin-right: 19px;
    min-width: 18px;
    max-height: 18px;
    background-size: 7.5px;
  }
}
