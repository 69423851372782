.popup__save-button {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 0;
  margin-top: 48px;
  padding: 0;
  background: #000;
  border-radius: 2px;
  min-height: 50px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #fff;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.popup__save-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media all and (max-width: 500px) {
  .popup__save-button {
    font-size: 14px;
    line-height: 17px;
    margin-top: 45px;
    min-height: 46px;
  }
}
