.profile__title {
  font-weight: 500;
  font-size: 42px;
  line-height: 48px;
  margin: 0;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media all and (max-width: 720px) {
  .profile__title {
    max-width: 100%;
    font-size: 27px;
    line-height: 33px;
  }
}
