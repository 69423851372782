.element__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin: 0;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
