.popup__input {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 0 9px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 0;
}
