.elements__list {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, 282px);
  grid-auto-rows: auto;
  justify-items: center;
  -webkit-column-gap: 17px;
     -moz-column-gap: 17px;
          column-gap: 17px;
  row-gap: 20px;
  margin: 0 auto;
  padding: 0;
}

@media all and (max-width: 880px) {
  .elements__list {
    max-width: calc(100% - 38px);
  }
}
