.popup__input-error {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ff0000;
  position: absolute;
  top: 32px;
  left: 0;
  z-index: 1;
  visibility: hidden;
}
