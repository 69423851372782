.popup__message {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  max-width: 358px;
  margin: 0 auto 60px;
}

@media all and (max-width: 500px) {
  .popup__message {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 50px;
    max-width: 244px;
  }
}
