.user-form__save-button {
  padding: 0;
  margin-top: 216px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  background: #fff;
  border: 0;
  border-radius: 2px;
  box-sizing: border-box;
  min-height: 50px;
}

.user-form__save-button:hover {
  cursor: pointer;
  opacity: 0.85;
}

@media all and (max-width: 450px) {
  .user-form__save-button {
    margin-top: 173px;
    font-size: 16px;
    line-height: 19px;
    min-height: 46px;
  }
}
