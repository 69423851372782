.header__action {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  margin: 6px 0 46px 24px;
  text-decoration: none;
}

@media all and (max-width: 920px) {
  .header__action {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

@media all and (max-width: 450px) {
  .header__action {
    font-size: 14px;
    line-height: 17px;
    margin-top: 0;
  }
}
