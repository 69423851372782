.header__action_type_exit {
  color: #a9a9a9;
  padding: 0;
  border: none;
  background-color: #000;
}

@media all and (max-width: 600px) {
  .header__action_type_exit {
    margin: 0 auto 40px;
  }
}

@media all and (max-width: 450px) {
  .header__action_type_exit {
    font-size: 18px;
    line-height: 22px;
  }
}
