.popup {
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0.7s ease-in, opacity 0.7s ease-in;
  -o-transition: visibility 0.7s ease-in, opacity 0.7s ease-in;
  transition: visibility 0.7s ease-in, opacity 0.7s ease-in;
}
