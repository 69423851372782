.header__email {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  margin: 6px 0 46px;
}

@media all and (max-width: 920px) {
  .header__email {
    margin-bottom: 30px;
  }
}

@media all and (max-width: 600px) {
  .header__email {
    margin-top: 40px;
    margin-bottom: 18px;
  }
}

