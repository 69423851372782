.popup__title {
  margin: 0 0 48px;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000;
}

@media all and (max-width: 500px) {
  .popup__title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 75px;
  }
}
