.elements {
  max-width: 880px;
  margin: 0 auto;
}

@media all and (max-width: 880px) {
  .elements {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
