.profile__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 9px;
  margin-right: 19px;
  white-space: nowrap;
  overflow: hidden;
}

@media all and (max-width: 720px) {
  .profile__text {
    max-width: calc(100% - 93px);
    margin-right: 10px;
    margin-left: 46px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 7px;
  }
}
